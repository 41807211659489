'use strict';

Gri.module({
  name: 'input-alpha',
  ieVersion: null,
  $el: $('.input-alpha'),
  container: '.input-alpha',
  fn: function () {
    
  }
});
